<div class="report-content-container">
  <div class="d-flex">
    <div class="report-title">
      {{ reportObject?.tableCaption }}
    </div>
    <div class="export-icon">
      <mat-icon class="icon" (click)="exportToPdf()" (keydown)="exportToPdf()">picture_as_pdf</mat-icon>
      <svg (click)="exportToExcel()" (keydown)="exportToExcel()" class="icon" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
        <path
          fill="currentColor"
          d="M14 2H6a2 2 0 0 0-2 2v16c0 1.11.89 2 2 2h12c1.11 0 2-.89 2-2V8zm4 18H6V4h7v5h5zm-5.1-5.5l2.9 4.5H14l-2-3.4l-2 3.4H8.2l2.9-4.5L8.2 10H10l2 3.4l2-3.4h1.8z"></path>
      </svg>
    </div>
  </div>
  <div
    class="report-table"
    [ngClass]="getReportTableClass()">
    <table aria-describedby="dxfw-report-content-table" id="dxfw-wdr-view-report-table">
      <thead>
        <tr>
          <th
            *ngFor="let header of tableHeader; index as headerIndex"
            class="dxfw-wdr-view-report-header-cell"
            [ngClass]="isComparisonReport() || isHistoricalReport() ? 'text-center' : isHierarchicalReport() && header?.format ? 'text-right' : 'text-left'">
            {{ header?.columnName }}
          </th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngIf="isHierarchicalReport() || isComparisonReport()">
          <tr
            *ngFor="let rowObj of viewReportTableData"
            [attr.data-level-id]="isHierarchicalReport() ? rowObj?.level_id : null"
            [ngStyle]="{
              'background-color': isHierarchicalReport() && rowObj?.level_id ? constant.VIEW_REPORT_COLOR_CODE[rowObj?.level_id] : ''
            }">
            <td
              *ngFor="let colName of rowObj?.rows; index as dataIndex"
              class="dxfw-wdr-view-report-body-cell"
              [ngClass]="!colName?.value || colName?.value === '-' ? 'text-center' : colName?.format ? 'text-right' : 'text-left'">
              {{
                colName?.value === '-'
                  ? colName?.value
                  : colName?.value && colName?.format === 'currency'
                  ? (colName?.value | currency)
                  : colName?.value && colName?.format === 'percent'
                  ? (colName?.value / 100 | percent)
                  : colName?.value ?? '-'
              }}
            </td>
          </tr>
        </ng-container>
        <ng-container *ngIf="isHistoricalReport()">
          <tr *ngFor="let rowObj of viewReportTableData; index as rowIndex">
            <ng-container *ngFor="let colName of rowObj?.rows; index as colIndex">
              <td
                *ngIf="skipSpannedColumn(rowIndex, colName, colIndex)"
                [attr.rowspan]="rowSpan(rowIndex, colName, colIndex)"
                class="dxfw-wdr-view-report-body-cell"
                [ngClass]="!colName?.value || colName?.value === '-' ? 'text-center' : colName?.format ? 'text-right' : 'text-left'">
                {{
                  colName?.value === '-'
                    ? colName?.value
                    : colName?.value && colName?.format === 'currency'
                    ? (colName?.value | currency)
                    : colName?.value && colName?.format === 'percent'
                    ? (colName?.value / 100 | percent)
                    : colName?.value ?? '-'
                }}
              </td>
            </ng-container>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>
